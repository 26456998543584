<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：销售视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview offer" id="offer_tableBox">
    <!-- 报价操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="getListDataByCondition(0)" :class="{'selLi':sel_searchBtn==0}">
            全部
          </li>
          <li @click="getListDataByCondition(1)" :class="{'selLi':sel_searchBtn==1}">
            已审
          </li>
          <li @click="getListDataByCondition(2)" :class="{'selLi':sel_searchBtn==2}">
            未审
          </li>
          <li v-if="orderType == 0" @click="getListDataByCondition(3)" :class="{'selLi':sel_searchBtn==3}">
            已转订单
          </li>
          <li v-if="orderType == 0" @click="getListDataByCondition(4)" :class="{'selLi':sel_searchBtn==4}">
            未转订单
          </li>
          <li>
            <el-popover placement="bottom-start" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <div>
                <el-form ref="form" :model="offer_searchData" size="mini" label-width="100px">
                  <el-form-item label="单号:">
                    <el-input v-model="offer_searchData.billingNumber" size="mini" placeholder="请输入内容" clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="客户名:" v-if="loginType == 0">
                    <el-input v-model="offer_searchData.customerName" size="mini" placeholder="请输入内容"
                      @focus="getCustomer" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="起止日期:">
                    <el-col :span="10">
                      <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.startTime"
                        style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="3" :push="1"> - </el-col>
                    <el-col :span="10">
                      <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.endTime"
                        style="width: 100%;"></el-date-picker>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="单据类型:">
                    <el-select v-model="offer_searchData.billsType" placeholder="请选择单据类型" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="(item,index) in billsModelData" :key="item.id" :label="item.bills_type"
                        :value="item.id" v-show="item.type_classify == orderType">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="结算方式:">
                    <el-select v-model="offer_searchData.OrderSettlement" placeholder="请选择结算方式" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in OrderSettlement" :label="elem.name" :value="elem.id">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="单据状态:">
                    <el-select v-model="offer_searchData.billsStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in billStateList" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="订单状态:" v-if="orderType == 0">
                    <el-select v-model="offer_searchData.orderStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in generateState" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="收款状态:" v-if="orderType == 1">
                    <el-select v-model="offer_searchData.collectStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in costInStateIn" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="发票状态:" v-if="orderType == 1">
                    <el-select v-model="offer_searchData.invoiceStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in invoiceState" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="出库状态:" v-if="orderType == 1">
                    <el-select v-model="offer_searchData.outStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in depotStateOut" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="采购状态:" v-if="orderType == 1">
                    <el-select v-model="offer_searchData.purchaseStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in purchaseState" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="生产状态:" v-if="orderType == 1">
                    <el-select v-model="offer_searchData.mesStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in mesState" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="退货状态:" v-if="orderType == 1">
                    <el-select v-model="offer_searchData.refundStatus" placeholder="请选择状态" style="width: 100%;"
                      clearable>
                      <el-option value="" key="-1" label="全部"></el-option>
                      <el-option v-for="elem in returnState" :label="elem.name" :value="elem.value">{{elem.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="备注:">
                    <el-input v-model="offer_searchData.remark" size="mini" placeholder="请输入内容" clearable></el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="billingview_searchBtnBox">
                      <el-button @click="getListDataByCondition(5)" type="primary">搜索</el-button>
                      <el-button @click="show_searchBox=false">取消</el-button>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <span slot="reference" class="billingview_searchBut" @click="showSearchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
          <li @click="getListDataByCondition(sel_searchBtn)">
            刷新<i class="el-icon-refresh-right"></i>
          </li>
          <li class="worker noHover" v-if="filterPower && filterPower.departmentType!=3 && loginType == 0">
            <userPowerSelect :departmentPowerData="departmentPower" :userPowerType="userPowerType"
              @getSelectData="getSelectUserPowerData"></userPowerSelect>
          </li>
          <li>
            <el-tooltip placement="bottom-start">
              <div slot="content">
                <ul>
                  <li>1.生产状态:订单生成的生产单审核后为生产中,对应生产单入库完成后为已生产。</li>
                </ul>
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="addBillsData">
            <i class="el-icon-plus"></i>新增
          </li>
          <li @click="operateBills(0)">
            <i class="el-icon-check"></i>提交
          </li>
          <li @click="operateBills(1)" v-if="loginType == 0">
            <i class="el-icon-document-checked"></i>审批
          </li>
          <li @click="operateBills(3)" v-if="loginType == 0">
            <i class="el-icon-document-checked"></i>反审
          </li>
          <li>
            <i class="el-icon-document-copy"></i>复制
          </li>
          <li>
            <billExport :billsTypeSign="0" :billsModelData="billsModelData" :billsClassify="orderType"
              :originBillData="offerSelData"></billExport>
          </li>
          <li v-if="loginType == 0">
            <el-dropdown trigger="click" placement="bottom" @command="generateOtherBill">
              <span class="el-dropdown-link">
                生成<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeGenerateOtherBill(0,offerSelData)" v-if="orderType == 0">销售订单
                </el-dropdown-item>
                <el-dropdown-item :command="beforeGenerateOtherBill(1,offerSelData)" v-if="orderType == 1">采购申请单
                </el-dropdown-item>
                <el-dropdown-item :command="beforeGenerateOtherBill(4,offerSelData)" v-if="orderType == 1">采购订单
                </el-dropdown-item>
                <el-dropdown-item :command="beforeGenerateOtherBill(2,offerSelData)" v-if="orderType == 1">生产计划单
                </el-dropdown-item>
                <el-dropdown-item :command="beforeGenerateOtherBill(3,offerSelData)" v-if="orderType == 1">销售出库单
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>

    <!-- 单据表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table ref="sellTableList" :data="offerListData" :summary-method="getSummaries"
        @select-all="getSelectedAllData" @select="getSelectedOfferData" row-key="id" border show-summary height="100%"
        style="width: 100%" v-if="show_again_load_table">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="35">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1 || elem.classify == orderType">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="30"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <div v-if="scope.row.saleOrderMain.isSubmit<4">
              <el-tooltip placement="right" effect="light">
                <div slot="content">
                  <ul class="billsList_relvanceTips scollStyle">
                    <li v-if="scope.row.saleOrderMain.isSubmit<4" @click="updateBillData(scope.row,1)">
                      <i class="el-icon-edit-outline operate_icon" title="修改"></i>
                      <span class="left_padding_10">修改</span>
                    </li>
                    <li @click="updateBillData(scope.row,2)">
                      <i class="el-icon-view operate_icon" title="预览"></i>
                      <span class="left_padding_10">预览</span>
                    </li>
                    <li v-if="[1,3].includes(scope.row.saleOrderMain.isSubmit)" @click="judgeRejectBills(scope.row)">
                      <i class="el-icon-warning-outline operate_icon" title="驳回"></i>
                      <span class="left_padding_10">驳回</span>
                    </li>
                    <li v-if="scope.row.saleOrderMain.isSubmit<4" @click="judgeDeleteBills(scope.row)">
                      <i class="el-icon-delete operate_icon" title="作废"></i>
                      <span class="left_padding_10">作废</span>
                    </li>
                  </ul>
                </div>
                <i class="el-icon-more icon_color_red"></i>
              </el-tooltip>
            </div>
            <div v-if="scope.row.saleOrderMain.isSubmit >= 4">
              <i class="el-icon-view operate_icon" title="预览" @click="updateBillData(scope.row,2)"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="saleOrderMain.billsNo" label="单号" sortable width="180"
          v-if="showListFiledArr.includes(0)" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" title="点击查看"
              @click="updateBillData(scope.row,2)">{{scope.row.saleOrderMain.billsNo}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saleOrderMain.billsDate" label="日期" width="120" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="billingTypeInfo.bills_type" label="单据类型" width="120" v-if="showListFiledArr.includes(2)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="saleOrderMain.isSubmit" label="单据状态" width="80" v-if="showListFiledArr.includes(3)">
          <template slot-scope="scope">
            <!-- 驳回显示 -->
            <div v-if="scope.row.saleOrderMain.isSubmit == 2">
              <rejectBillTips :originBillId="scope.row.saleOrderMain.id"
                :originBillState="scope.row.saleOrderMain.isSubmit" :billsSign="0"></rejectBillTips>
            </div>

            <!-- 其它显示 -->
            <div v-else>
              <el-tooltip :disabled="scope.row.originBillsMain.length == 0" placement="right" effect="light">
                <div slot="content">
                  <!-- 源单信息 -->
                  <ul class="billsList_relvanceTips" v-if="scope.row.saleOrderMain.isSubmit != 2">
                    <li v-for="elem in scope.row.originBillsMain"
                      @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">
                      <span class="billsDate">{{elem.billsDate}}</span>
                      【{{elem.billsNo}}】(
                      <span v-for="(elemJ,indexJ) in billStateList"
                        v-if="elem.isSubmit== elemJ.value">{{elemJ.name}}</span>
                      )
                    </li>
                  </ul>
                </div>
                <el-button v-for="(elem,index) in billStateList" v-if="scope.row.saleOrderMain.isSubmit == elem.value"
                  :type="elem.class" size="mini">{{elem.name}}</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!-- 报价特有字段 -->
        <el-table-column prop="orderState" label="订单状态" width="80"
          v-if="orderType == 0 && showListFiledArr.includes(20)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'generateState'"
              :originBillState="scope.row.saleOrderMain.orderState" :originBillStateType="'0.1'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <!-- 订单特有字段 -->
        <el-table-column prop="payState" label="收款状态" width="80" v-if="orderType == 1 && showListFiledArr.includes(4)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'costInStateIn'"
              :originBillState="scope.row.saleOrderMain.payState" :originBillStateType="'0.2'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="receiptState" label="发票状态" width="80"
          v-if="orderType == 1 && showListFiledArr.includes(5)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'invoiceState'"
              :originBillState="scope.row.saleOrderMain.receiptState" :originBillStateType="'0.3'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="depotState" label="出库状态" width="80"
          v-if="orderType == 1 && showListFiledArr.includes(6)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'depotStateOut'"
              :originBillState="scope.row.saleOrderMain.depotState" :originBillStateType="'0.4'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="purchaseState" label="采购状态" width="80"
          v-if="orderType == 1 && showListFiledArr.includes(18)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'purchaseState'"
              :originBillState="scope.row.saleOrderMain.purchaseState" :originBillStateType="'0.7'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="mesState" label="生产状态" width="80" v-if="orderType == 1 && showListFiledArr.includes(19)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'mesState'"
              :originBillState="scope.row.saleOrderMain.mesState" :originBillStateType="'0.5'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="refundState" label="退货状态" width="80"
          v-if="orderType == 1 && showListFiledArr.includes(21)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.saleOrderMain.id" :stateKeyName="'returnState'"
              :originBillState="scope.row.saleOrderMain.refundState" :originBillStateType="'0.6'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>

        <!-- 其他公共字段 -->
        <el-table-column prop="saleOrderMain.totalNumber" label="数量" width="80" v-if="showListFiledArr.includes(23)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.discount" label="优惠金额" width="120" v-if="showListFiledArr.includes(9)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="saleOrderMain.customer_name" label="客户名称" width="240" v-if="showListFiledArr.includes(7)"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span
              :title="scope.row.saleOrderMain.customerBillsName +':'+(!!scope.row.saleOrderMain.customerBills?scope.row.saleOrderMain.customerBills:'无')">{{scope.row.saleOrderMain.customer_name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saleOrderMain.relevanceBillsNo" label="源单号" width="180"
          v-if="showListFiledArr.includes(24)" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer" v-if="scope.row.originBillsMain.length > 0"
              @click="getRelvanceDetail(scope.row.originBillsMain[0],scope.row.originBillsMain[0].sign,scope.row.originBillsMain[0].billsClassify)">
              {{scope.row.saleOrderMain.relevanceBillsNo}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saleOrderMain.createUserName" label="制单人" width="80" v-if="showListFiledArr.includes(11)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.money" label="单据金额" width="120" v-if="showListFiledArr.includes(8)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.practicalAmount" label="交易金额" width="120"
          v-if="showListFiledArr.includes(22)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="saleOrderMain.giveDate" label="交货日期" width="120" v-if="showListFiledArr.includes(10)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.addTime" label="制单时间" width="160" v-if="showListFiledArr.includes(17)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.checkUserName" label="审核人" width="80" v-if="showListFiledArr.includes(12)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.checkTime" label="审核时间" width="180" v-if="showListFiledArr.includes(13)"
          show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column prop="saleOrderMain.giveDate" label="关联单号" width="120"></el-table-column> -->
        <!-- <el-table-column prop="saleOrderMain.relevanceOrder" label="订单号" width="150" v-if="show_ddh"></el-table-column> -->
        <!-- <el-table-column prop="saleOrderMain.relevancePurchase" label="采购单" width="150"></el-table-column> -->
        <!-- <el-table-column prop="saleOrderMain.relevanceProduction" label="生产单" width="150" v-if="show_scd"> -->
        <!-- </el-table-column> -->
        <!-- <el-table-column prop="wwd" label="委外单" width="150" v-if="show_wwd"></el-table-column> -->
        <!-- <el-table-column prop="ck" label="出库" width="80"></el-table-column> -->
        <!-- <el-table-column prop="fp" label="发票" width="80"></el-table-column> -->
        <el-table-column prop="saleOrderMain.printNum" label="打印" width="80" v-if="showListFiledArr.includes(14)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.deptName" label="所属部门" width="150" v-if="showListFiledArr.includes(15)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleOrderMain.businessName" label="业务员" width="80" v-if="showListFiledArr.includes(16)"
          show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="changePageSize" @current-change="changeCurrentPage"
        :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <!-- 驳回条件弹框 -->
    <el-dialog title="驳回原因" :visible.sync="show_reject" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="rejectData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_reject = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rejectBills">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 作废条件弹框 -->
    <el-dialog title="作废原因" :visible.sync="show_delete" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="deleteData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_delete = false">取 消</el-button>
        <el-button size="small" type="primary" @click="deleteBillsData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 反审条件弹框 -->
    <el-dialog title="反审原因" :visible.sync="show_again" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="againstData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_again = false">取 消</el-button>
        <el-button size="small" type="primary" @click="againstBillsData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 订单生成生产单后的提示弹框(样式在公共样式中) -->
    <el-dialog title="生成成功" class="padding_top_10" :visible.sync="show_generateMes" width="400px" append-to-body>
      <div class="generageAll">
        <!-- 生成成功提示弹框 -->
        <div class="generageTitle">订单转换生产单完成,是否跳转生产单界面</div>
        <!-- 总计 -->
        <div class="generateTotal">
          <span class="totalItem">处理总数:{{generateResData.allNum}}</span>
          <span class="totalItem">
            成功数:
            <span class="color_success">{{generateResData.successNum}}</span>
          </span>
          <span class="totalItem">
            失败数:
            <span class="color_danger">{{generateResData.errorNum}}</span>
          </span>
        </div>
        <!-- 生成详情 -->
        <div class="generateDetail">
          <!-- 成功详情 -->
          <div class="detailSuccess">
            <span class="successTitle">成功详情:</span>
            <ul class="detailItem">
              <li>
                <span class="itemTitle">新生成数:</span>
                {{generateResData.newGenerateNum}}
              </li>
              <li>
                <span class="itemTitle">重复生成数:</span>
                {{generateResData.repetitionNum}}
              </li>
            </ul>
          </div>
          <!-- 失败详情 -->
          <div class="detailError">
            <span class="errorTitle">失败详情:</span>
            <ul class="detailItem">
              <li>
                <span class="itemTitle">未设bom数:</span>
                {{generateResData.noSetBomNum}}
              </li>
              <li>
                <span class="itemTitle">已生成数:</span>
                {{generateResData.isGenerateNum}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_generateMes = false">取 消</el-button>
        <el-button size="small" type="primary" @click="skipMesListPage">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 工时信息弹框(下一节点工时信息为员工填写时用到) -->
    <el-dialog title="预计工时" :visible.sync="show_hourseBox" width="400px" :before-close="closeHourseBox" append-to-body>
      <!-- 工时信息弹框 -->
      <div class="billing_dialog_form">
        <ul class="single_ul">
          <li>
            <div class="title">下节点预计工时:</div>
            <div class="mulData">
              <el-input size="small" class="mulInput" type="number" v-model.number="nextHourse" placeholder="请输入工时时长">
              </el-input>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 1">天</span>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 2">小时</span>
            </div>
          </li>
        </ul>
      </div>

      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="commitNodeData(nextNodeBaseInfo,nextHourse)">确认</el-button>
        <el-button size="small" @click="closeHourseBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :generateBillsSign="generateBillsSign" :generateBillsOriginData="generateBillsOriginData"
      :billsTypeDefaultCode="billsTypeDefaultCode" :isGenerateOperateType="isGenerateOperateType"
      :dictionariesDefaultData="dictionariesDefaultData" v-if="show_nodeBills"></nodeBills>

    <!-- 客户选择弹框 -->
    <customerList @SelectedData="getCustomerData" v-if="show_customerBox"></customerList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入api接口
  import workApi from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import productApi from '@/network/production/production.js'; //生产模块
  import api from '@/network/hr/organization.js'; //人力模块

  // 导入组件
  import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  import userPowerSelect from '@/components/commComponent/power/user_power_select'; //用户权限查询组件
  import billExport from '@/components/commComponent/dialog/bill_export'; //单据打印组件
  import relvanceBillsTip from '@/components/commComponent/tooltip/relvanceBillsTip'; //单据关联其它单据信息
  import relvanceBillTipStrip from '@/components/commComponent/tooltip/relvanceBillTipStrip.vue'; //单据关联其它单据信息
  import rejectBillTips from '@/components/commComponent/tooltip/rejectBillTips'; //单据驳回信息显示组件

  export default {
    name: 'sell_view',
    data() {
      return {
        //控制开关
        show_searchBox: false, //是否显示搜索
        show_ddh: true, //
        show_scd: true, //
        show_wwd: true, //

        //节点相关
        show_hourseBox: false, //控制显示工时信息弹框
        nextHourse: 2, //下一节点预计工时
        nextNodeBaseInfo: {}, //下一节点基本信息

        // 当前组件数据
        offerListData: [], //报价单列表数据
        offerStateList: [], //报价单状态列表
        offerSelData: [], //报价单列表已选数据
        offerCurrSelData: {}, //报价单当前操作单据
        generateResData: {}, //订单生成生产单结果集
        rejectData: '', //驳回条件
        deleteData: '', //作废条件
        againstData: '', //反审条件
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        // 高级搜索框数据
        offer_searchData: { //高级搜索数据
          billingNumber: '', //单号
          customerName: '', //客户名
          customerId: '', //客户id
          startTime: '', //开始时间
          endTime: '', //结束时间
          billsType: '', //单据类型
          billsStatus: '', //单据状态
          OrderSettlement:'',//结算方式
          collectStatus: '', //收款状态
          orderStatus: '', //订单状态
          invoiceStatus: '', //发票状态
          outStatus: '', //出库状态
          purchaseStatus: '', //采购状态
          mesStatus: '', //生产状态
          refundStatus: '', //退货状态
          remark: '' //备注
        },
        billsModelData: {}, //单据模板
        personnel: -1, //员工
        departmentPower: [], //权限部门
        filterPower: {}, //符合条件的权限对象

        //控制开关
        show_nodeBills: false, //控制显示公共单据组件弹框
        show_reject: false, //控制驳回弹框是否显示
        show_delete: false, //控制作废弹框是否显示
        show_again: false, //控制反审弹框是否显示
        show_customerBox: false, //控制是否加载客户弹框
        show_setFiledBox: false, //控制是否显示列表显示字段设置框
        show_generateMes: false, //控制订单生成生产单后的弹框
        show_again_load_table: true, //是否重新加载表格组件(防止路由切换等导致界面错位)

        //销售其它关联单据
        isGetEndData: true, //开始查询单据信息(为0时不查询 为1时查询)
        relevanceDepotMain: [], //出库单数据

        //传入公共单据子组件的数据
        nodeTypeCode: 'SYS-NODE-TYPE-001', //节点分类code
        billsTypeSign: 0, //单据分类标签(0代表销售)
        billsClassify: -1, //单据分类
        operateType: 0, //操作类型(0:新增 1:修改  2:查看)
        generateBillsId: -1, //需要生成其他单据的id
        isGenerateOperateType: -1, //是否为生成类型操作
        generateBillsSign: -1, //源单分类标识
        generateBillsOriginData: {}, //需要生成其他单据的数据源
        dictionariesDefaultData: {}, //数据字典默认数据
        billsTypeDefaultCode: '', //默认单据类型
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },

        //用户查询权限子组件
        userPowerType: 1, //默认全公司

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //辅助数据
        sel_searchBtn: 0, //当前选中查询条件(0:全部 1:已审 2:未审 3:状态 4:高级查询)
        loading: '', //loading框
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        LoginCustomerInfo: state => state.user.LoginCustomerInfo, //当前系统登录的客户信息
        loginType: state => state.user.loginType, //当前系统登录方式
        bills_operate_btn: state => state.commComponent.bills_operate_btn, //新建单据弹框操作按钮
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        userPowerInfo: state => state.user.userPowerInfo, //权限
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        sellBillsKeys: state => state.system.sellBillsKeys, //单据导出字段
        billStateList: state => state.bill_state.billStateList, //单据状态
        saleListFileData: state => state.bill_list_config.saleListFileData, //销售列表可显示字段
        mesState: state => state.bill_state.mesState, //生产状态
        generateState: state => state.bill_state.generateState, //生成状态
        costInStateIn: state => state.bill_state.costInStateIn, //收款状态
        invoiceState: state => state.bill_state.invoiceState, //发票状态
        depotStateOut: state => state.bill_state.depotStateOut, //出库状态
        purchaseState: state => state.bill_state.purchaseState, //采购状态
        returnState: state => state.bill_state.returnState, //退货状态
        OrderSettlement: state => state.system.OrderSettlement, //结算方式
      }),
      //路由传入的参数(销售单据类型(0:报价单  1:订单))
      orderType() {
        //路由获取的参数需要转换为int类型
        return Number(this.$route.query.orderType);
      },
      //路由传入的默认单据类型code
      orderBillCode() {
        return this.$route.query.orderBillCode;
      }
    },
    created() {},
    mounted() {
      // 初始化状态数据
      this.initStateList();
      //初始化列表显示字段数据
      this.initListShowFiledData();
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
    },
    watch: {
      //监听新建弹框关闭或显示
      show_nodeBills(newVal) {
        if (!newVal && this.bills_operate_btn != 4) {
          //获取列表数据
          this.getListDataByCondition(this.sel_searchBtn);
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
      // 监听路由发生变化
      '$route': 'getPathChange',
    },
    updated() {
      this.$nextTick(() => {
        this.$refs['sellTableList'].doLayout();
      });
    },
    methods: {
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        // 'USER_POWER_INFO', //用户权限
      ]),
      ...mapMutations({}),
      ...mapActions([
        'getelectronicTemplate', //获取所有单据类型
        'getAllUserData', //请求企业用户数据
        "getAllSupplierSettlement", //获取结算方式
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '列表初始化中,请稍候...');
        //判断用户权限
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
        }
        //获取用户查询权限权限范围
        await this.getUserPower();
        //获取结算方式
        this.getAllSupplierSettlement();
        //获取单据类型数据
        this.getBillTemplateData();
        this.loading.close();
        //获取全部列表数据
        this.getListDataByCondition(0);
      },

      /* 监听路由发生变化执行方法 */
      getPathChange() {
        //获取单据类型数据
        this.getBillTemplateData();
        //获取全部列表数据
        this.getListDataByCondition(0);
      },

      /* 显示客户弹框 */
      getCustomer() {
        this.show_customerBox = true;
        this.SHOW_CUSTOMERBOX(true); //客户弹框
      },

      /* 获取所选择的客户数据 */
      getCustomerData(data) {
        this.show_customerBox = false;
        this.offer_searchData.customerName = data.customer_name; //客户
        this.offer_searchData.customerId = data.customer_id; //客户id
        this.$forceUpdate()
      },

      /* 获取单据类型 */
      async getBillTemplateData() {
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        if (this.billTemplateData.length != 0) {
          //根据分类获取单据分类信息
          this.billsModelData = this.commonJsExtend.getBillsClassifyDataByType(2, this.nodeTypeCode, this
            .billTemplateData).children;
          //根据code设置默认值
          if (!!this.orderBillCode) {
            //判断是否为订单类型(查询所有订单)
            if (this.orderBillCode == 'DOC-S-006' || this.orderBillCode == 'DOC-S-001') { //报价单和订单查询所有的单据类型
              this.offer_searchData.billsType = '';
            } else {
              this.offer_searchData.billsType = this.commonJsExtend.getBillsDataByType(2, this.orderBillCode, this
                .billTemplateData).id
            }
          }
        }
      },

      /* 获取用户权限组件选择出来的数据 */
      getSelectUserPowerData(data) {
        // 判断是否为全部查询
        if (data[0] == '') { //全部查询
          this.personnel = -1;
        } else { //非全部查询
          this.personnel = data;
        }
        //调用查询方法
        this.getListDataByCondition(0);
      },

      /* 根据条件查询列表信息type(0:全部 1:已审 2:未审  3:状态  4:高级查询  5:条件查询)*/
      async getListDataByCondition(type) {
        //判断是否有查询权限
        if (this.orderType == 0) { //报价单
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(14, 1, 1, '查询')) {
            this.offerListData = []
            return
          }
        }
        if (this.orderType == 1) { //订单
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(14, 2, 1, '查询')) {
            this.offerListData = []
            return
          }
        }
        //设置查询按钮选中
        this.sel_searchBtn = type;
        //查询公共参数
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
          deleteFlag: 0, //删除标识(0:未作废  1:已作废)
          isOrder: this.orderType, //是否订单
        }

        //判断是否为客户登录模块下
        if (this.loginType == 1) { //客户登录模块下客户id必传
          data.customerId = this.LoginCustomerInfo.customer_id;
        }

        //定义高级查询条件
        let {
          billingNumber, //单号
          customerName, //客户名
          customerId, //客户id
          startTime, //开始时间
          endTime, //结束时间
          billsType, //单据类型
          billsStatus, //单据状态
          OrderSettlement,//结算方式
          collectStatus, //收款状态
          orderStatus, //订单状态
          invoiceStatus, //发票状态
          outStatus, //出库状态
          mesStatus, //生产状态
          refundStatus, //退货状态
          purchaseStatus, //采购状态
          remark //备注
        } = this.offer_searchData

        //判断查询类型
        if (type == 0) { //全部
        } else if (type == 1) { //已审
          data.isSubmit = 4
        } else if (type == 2) { //未审
          data.isSubmit = 1
        } else if (type == 3) { //已转订单
          data.isCreateOrder = 1
        } else if (type == 4) { //未转订单
          data.isCreateOrder = 0
        } else if (type == 5) { //高级条件查询
          let today = new Date()
          today.setHours(0, 0, 0, 0)
          if ((startTime != '' && startTime != null) || (endTime != '' && endTime != null)) { //起止时间其中一个不为空
            if (startTime == '' || endTime == '') { //起止时间其中一个为空
              this.$message({
                type: 'warning',
                message: '查询条件为起止时间时必须两个都填写!',
                duration: this.elDuration
              })
              return
            } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
              this.$message({
                type: 'warning',
                message: '结束日期不能早于开始日期!',
                duration: this.elDuration
              })
              return
            } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
              .getTime()) { //所选择的日期不能早于当前日期
              this.$message({
                type: 'warning',
                message: '所选择的日期不能早于当前日期!',
                duration: this.elDuration
              })
              return
            } else { //限制日期选择区间
              let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
              if (duringTime > 90) {
                this.$message({
                  type: 'warning',
                  message: '日期区间不能超过90天!',
                  duration: this.elDuration
                })
                return
              }
            }
          }
          this.show_searchBox = false;
        }
        //判断当前员工查询权限
        if (this.userPowerType == 3) { //仅个人
          data.createUserId = this.UserInfo.user_id;
        } else { //全公司,本部门,指定部门
          //判断是否查询全部
          if (this.personnel == -1) { //查询全部
            let userArr = [];
            this.departmentPower.forEach((itemI, indexI) => {
              if (itemI.userList) {
                itemI.userList.forEach((itemJ, indexJ) => {
                  userArr.push(itemJ.user_id);
                })
              }
            })
            data.createUserId = userArr.join(",");
          } else {
            data.createUserId = this.personnel.join(",");
          }
        }

        //单据类型
        if (billsType != '') {
          data.billingTypeId = billsType;
        }
        //设置查询条件(单按钮查询不需要带高级查询条件)
        if (![0, 1, 2, 3, 4].includes(type)) {
          //单号
          if (billingNumber != '') {
            data.billsNo = billingNumber;
          }
          //客户名
          if (customerName != '') {
            data.customerId = customerId;
          }
          // 开始时间
          if (startTime != '') {
            data.startTime = this.commonJsExtend.getDateTime(startTime, 0);
          }
          //结束时间
          if (endTime != '') {
            data.endTime = this.commonJsExtend.getDateTime(endTime, 0);
          }
          //单据状态
          if (!!billsStatus.toString()) {
            data.isSubmit = billsStatus;
          }
          //结算方式
          if(!!OrderSettlement.toString()){
            data.OrderSettlement = OrderSettlement;
          }
          //收款状态
          if (!!orderStatus.toString()) {
            data.orderState = orderStatus;
          }
          //收款状态
          if (!!collectStatus.toString()) {
            data.payState = collectStatus;
          }
          //发票状态
          if (!!invoiceStatus.toString()) {
            data.receiptState = invoiceStatus;
          }
          //出库状态
          if (!!outStatus.toString()) {
            data.depotState = outStatus;
          }
          //采购状态
          if (!!purchaseStatus.toString()) {
            data.purchaseState = purchaseStatus;
          }
          //生产状态
          if (!!mesStatus.toString()) {
            data.mesState = mesStatus;
          }
          //退货状态
          if (!!refundStatus.toString()) {
            data.refundState = refundStatus;
          }
          //备注
          if (remark != '') {
            data.remark = remark;
          }
        }

        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
        // 发送请求
        sellApi.findSaleOrderMainByCondition(data).then(res => {
          //关闭弹框
          this.loading.close();
          //验证请求后的结果
          if (res.code == 200) {
            //将数据查询到的数据存入data
            this.offerListData = res.data.rows;
            //获取分页数据
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取用户权限 */
      async getUserPower() {
        //处理用户权限数据
        let childrenLevel = ''; //二级用户权限
        if (this.orderType == 0) { //报价单
          childrenLevel = 1
        } else if (this.orderType == 1) { //订单
          childrenLevel = 2
        }
        //判断是否有用户权限数据
        if (this.userPowerInfo) {
          this.filterPower = this.userPowerInfo.filter(item => item.parentLevel == 14 && item.childrenLevel ==
            childrenLevel)[0] //获取符合条件的权限对象
          if (this.filterPower) {
            let departments = []
            let data = {}
            //获取权限部门类型
            this.userPowerType = parseInt(this.filterPower.departmentType);
            //判断权限部门
            if (this.filterPower.departmentType == 0) { //本部门
              departments.push(this.UserInfo.dept_id) //传入当前登录用户部门id
              data.deptId = departments.join(',')
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 1) { //全公司
              data.deptId = ''
              data.isAdmin = 1
            } else if (this.filterPower.departmentType == 2) { //指定部门
              departments.push(this.filterPower.departmentId) //传入部门id
              data.deptId = departments.join(',')
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 3) { //仅个人
            }
            await api.findFirstLevelDeptUser(data).then(res => {
              if (res.code == 200) {
                this.departmentPower = res.data.departmentList; //赋值并倒叙
              }
            })
          }
        }
      },

      /* 添加销售单据 */
      addBillsData() {
        //判断是否有查询权限
        if (this.orderType == 0) { //报价单
          //判断是否有新增权限
          if (this.commonJsExtend.isHaveThePower(14, 1, 2, '新增')) {
            return
          }
        }
        if (this.orderType == 1) { //订单
          //判断是否有新增权限
          if (this.commonJsExtend.isHaveThePower(14, 2, 2, '新增')) {
            return
          }
        }
        //将操作设置为新增
        this.operateType = 0;
        //获取需要回显的单据id(新增为-1)
        this.generateBillsId = -1;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 0;
        //设置单据分类类型
        this.billsClassify = this.orderType;
        //设置单据分类code为销售
        this.nodeTypeCode = 'SYS-NODE-TYPE-001';
        //设置默认单据类型
        this.billsTypeDefaultCode = this.orderBillCode;
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 修改或预览销售单据 type(1:修改  2:预览) operateOrigin操作来源(不传:本组件  1:单据公共组件)*/
      updateBillData(data, type, operateOrigin, nodeTypeCode) {
        //报价单
        if (this.orderType == 0 && type == 1) {
          //判断是否有修改权限
          if (this.commonJsExtend.isHaveThePower(14, 1, 4, '修改')) {
            return
          }
        }
        //订单
        if (this.orderType == 1 && type == 1) {
          //判断是否有修改权限
          if (this.commonJsExtend.isHaveThePower(14, 2, 4, '修改')) {
            return
          }
        }
        //将操作设置为修改或预览
        this.operateType = type;
        //获取需要回显的单据id
        this.generateBillsId = data.saleOrderMain.id;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 0;
        //设置单据分类类型
        this.billsClassify = this.orderType;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-001';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.saleOrderMain.statusId, //节点id
          processId: data.saleOrderMain.processId, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.billingTypeInfo.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 审核单据 type(0:提交 1:审批  2:驳回  3:反审) */
      operateBills(type) {
        // 判断是否可以处理
        if (this.offerSelData.length == 0) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要处理的数据!',
            duration: this.elDuration
          })
          return
        } else if (this.offerSelData.length > 1) { //大于1的情况
          this.$message({
            type: 'warning',
            message: '一次只能处理一条数据!',
            duration: this.elDuration
          })
          return
        }
        //将当前选择数据存入data
        this.offerCurrSelData = this.offerSelData[0];

        // 判断哪种操作
        if (type == 0) { //提交
          //定义可提交的状态
          let deleteState = [0, 2] //0:未提交  2:已驳回
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.offerSelData[0].saleOrderMain.isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可提交',
              duration: this.elDuration
            })
            return
          }
          //定义传入后端的值
          let submitData = {
            isSubmit: 1, //状态(设置为提交)
            billingName: this.offerSelData[0].billingTypeInfo.bills_type, //	单据类型名称
            order_id: this.offerSelData[0].saleOrderMain.id, //销售模块单据id
            processId: this.offerSelData[0].saleOrderMain.processId, //流程id
            status_id: this.offerSelData[0].saleOrderMain.statusId, //节点id
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '提交审核中,请稍候...');
          // 发送提交审核请求
          sellApi.updateOrderSubmit(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.getListDataByCondition(this.sel_searchBtn);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 1) { //审批(通过)
          //报价单
          if (this.orderType == 0) {
            //判断是否有审核权限
            if (this.commonJsExtend.isHaveThePower(14, 1, 7, '审核')) {
              return
            }
          }
          //订单
          if (this.orderType == 1) {
            //判断是否有审核权限
            if (this.commonJsExtend.isHaveThePower(14, 2, 7, '审核')) {
              return
            }
          }
          // 定义可审核状态
          let deleteState = [1, 3, 5] //1:初审中  3:复审中  5:终审中
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.offerSelData[0].saleOrderMain.isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可审批',
              duration: this.elDuration
            })
            return
          }
          this.$confirm('您确认审核该单据吗,审核后的单据无法修改和作废?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let processStatusId = []
            if (this.offerSelData[0].processStatusId) {
              processStatusId = this.offerSelData[0].processStatusId.split(',') //节点id转数组
            }
            let statusId = this.offerSelData[0].saleOrderMain.statusId //当前节点id
            let statusIdIndex = processStatusId.indexOf(statusId + '') //当前节点id在数组中的索引
            let nextNodeId = processStatusId[statusIdIndex + 1] //下一节点id
            if (nextNodeId != processStatusId.pop()) { //当下一节点不为最后一个时
              let nodeNextData = {
                id: nextNodeId - ''
              }
              // 发送请求获取下一节点信息(不需要加载loading框)
              workApi.getWorkflowSetupNodeById(nodeNextData).then(res => {
                if (res.code == 200) {
                  //获取节点基本信息
                  if (res.data.node.working_type == 0) { //当工时类型为人工填写时
                    this.nextNodeBaseInfo = res.data.node;
                    this.show_hourseBox = true //显示工时弹框
                  } else {
                    this.show_hourseBox = false //关闭工时弹框
                    this.commitNodeData(res.data.node) //发送审核请求
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: '节点信息获取失败,请确认...',
                    duration: this.elDuration
                  })
                }
              })
            } else { //当下一节点为最后一个时
              this.commitNodeData({
                working_hours: 0,
                working_hours_type: 0,
                working_type: 0
              }) //发送审核请求
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消审核',
              duration: this.elDuration
            });
          });
        } else if (type == 2) { //驳回
          //定义可驳回状态
          let rejectState = [1, 3]; //1:审核中  3:复审中
          // 判断是否满足驳回条件
          if (!rejectState.includes(this.offerSelData[0].saleOrderMain.isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可驳回',
              duration: this.elDuration
            })
            return
          }
          //显示驳回弹框
          this.show_reject = true;
          //清空驳回条件
          this.rejectData = "";
        } else if (type == 3) { //反审
          //定义可反审状态
          let againstState = [4]; //已审核
          // 判断是否满足反审条件
          if (!againstState.includes(this.offerSelData[0].saleOrderMain.isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可反审',
              duration: this.elDuration
            })
            return
          }
          //显示反审弹框
          this.show_again = true;
          //清空反审条件
          this.againstData = "";
        }
      },

      /* 审核请求 */
      commitNodeData(dataInfo, nextHourse) {
        //获取工时相关信息
        let {
          working_hours,
          working_hours_type,
          working_type,
        } = dataInfo
        //当员工填写时
        if (nextHourse) {
          working_hours = nextHourse //工时为输入框的值
        }
        //定义传入后端的值
        let submitData = {
          //基本数据
          billingName: this.offerSelData[0].billingTypeInfo.bills_type, //	单据类型名称
          relevanceId: this.offerSelData[0].saleOrderMain.id, //单据id
          statusId: this.offerSelData[0].saleOrderMain.statusId, //节点id
          processId: this.offerSelData[0].saleOrderMain.processId, //流程id
          //通过信息
          workingHours: working_hours, //工时时长
          workingHoursType: working_hours_type, //工时单位
          workingType: working_type, //工时类型
          isFinish: 0, //是否完成
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '审核通过中,请稍候...');
        // 发送通过审核请求
        sellApi.updateOrderStatusYes(submitData).then(res => {
          this.loading.close();
          this.show_hourseBox = false; //关闭工时弹框
          if (res.code == 200) {
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示搜索弹框 */
      showSearchBox() {
        this.show_searchBox = !this.show_searchBox
      },

      /* 判断是否驳回单据 */
      judgeRejectBills(data) {
        //定义可驳回状态
        let rejectState = [1, 3]; //1:审核中  3:复审中
        // 判断是否满足驳回条件
        if (!rejectState.includes(data.saleOrderMain.isSubmit)) {
          this.$message({
            type: 'warning',
            message: '当前状态不可驳回',
            duration: this.elDuration
          })
          return
        }
        this.$confirm("您确认驳回该单据吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //将当前驳回数据存入data
          this.offerCurrSelData = data;
          //显示驳回原因弹框
          this.show_reject = true;
          //清空驳回原因
          this.rejectData = "";
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消驳回',
            duration: this.elDuration
          });
        })
      },

      /* 驳回单据 */
      rejectBills() {
        // 判断驳回原因是否已填
        if (this.rejectData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写驳回原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_reject = false;
        //定义传入后端的值
        let submitData = {
          //基本数据
          billingName: this.offerCurrSelData.billingTypeInfo.bills_type, //	单据类型名称
          relevanceId: this.offerCurrSelData.saleOrderMain.id, //单据id
          statusId: this.offerCurrSelData.saleOrderMain.statusId, //节点id
          processId: this.offerCurrSelData.saleOrderMain.processId, //流程id
          isSubmit: 2, //订单状态
          //驳回信息
          rejectReason: this.rejectData, //驳回原因
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '单据驳回中,请稍候...');
        // 发送驳回请求
        sellApi.updateOrderStatusNo(submitData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据驳回成功!',
              duration: this.elDuration
            })
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断是否作废单据 */
      judgeDeleteBills(data) {
        //报价单
        if (this.orderType == 0) {
          //判断是否有作废权限
          if (this.commonJsExtend.isHaveThePower(14, 1, 3, '作废')) {
            return
          }
        }
        //订单
        if (this.orderType == 1) {
          //判断是否有作废权限
          if (this.commonJsExtend.isHaveThePower(14, 2, 3, '作废')) {
            return
          }
        }
        this.$confirm("您确认作废该数据吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //将当前作废数据存入data
          this.offerCurrSelData = data;
          //显示作废原因弹框
          this.show_delete = true;
          //清空作废原因
          this.deleteData = "";
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消作废',
            duration: this.elDuration
          });
        })
      },

      /* 作废单据 */
      deleteBillsData() {
        // 判断作废原因是否已填
        if (this.deleteData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写作废原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_delete = false;
        //定义传入后端的数据
        let deleteData = {
          orderId: this.offerCurrSelData.saleOrderMain.id,
          reason: this.deleteData,
          statusId: this.offerCurrSelData.saleOrderMain.statusId
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '单据作废中,请稍候...');
        // 发送请求
        sellApi.deleteSaleOrderMain(deleteData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据作废成功!',
              duration: this.elDuration
            })
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /* 反审单据 */
      againstBillsData() {
        // 判断反审原因是否已填
        if (this.againstData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写反审原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_again = false;
        //定义传入后端的数据
        let againstData = {
          billsId: this.offerCurrSelData.saleOrderMain.id,
          reason: this.againstData,
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '单据反审中,请稍候...');
        // 发送请求
        sellApi.againstOrderBill(againstData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '反审成功,单据已回到初审状态!',
              duration: this.elDuration
            })
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /* 生成其他单据前处理的数据 */
      beforeGenerateOtherBill(type, data) {
        return {
          'type': type, //类型
          'data': data //数据
        }
      },

      /* 生成其它单据 */
      generateOtherBill(command) {
        // 判断是否可以处理
        if (command.data.length == 0) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要处理的数据!',
            duration: this.elDuration
          })
          return
        } else if (command.data.length > 1) { //大于1的情况
          this.$message({
            type: 'warning',
            message: '一次只能处理一条数据!',
            duration: this.elDuration
          })
          return
        } else if (![4, 5, 6].includes(command.data[0].saleOrderMain.isSubmit)) { //审核未通过的情况(4,5表示审核已完成)
          this.$message({
            type: 'warning',
            message: '该数据还未审核,无法生成!',
            duration: this.elDuration
          })
          return
        }
        //获取需要生成的单据id
        this.generateBillsId = command.data[0].saleOrderMain.id;
        //判断需要生成哪种单据
        if (command.type == "0") { //生成订单
        //（徐）20220824确认 订单只有未生成的才给予生成
        if(command.data[0].saleOrderMain.orderState==2||command.data[0].saleOrderMain.orderState==3||command.data[0].saleOrderMain.orderState==1){
          this.$message({
            type: 'warning',
            message: '该数据已有生成单据，请不要重复生成',
            duration: this.elDuration
          })
          return
        }
          //判断是否有(销售订单)新增权限
          if (this.commonJsExtend.isHaveThePower(14, 2, 2, '新增')) {
            return
          }
          //将操作设置为新增
          this.operateType = 0;
          //获取需要回显的单据id
          this.generateBillsId = command.data[0].saleOrderMain.id;
          //获取需要生成其他单据的数据源
          this.generateBillsOriginData = command.data[0];
          //设置源单分类标识
          this.generateBillsSign = 0;
          //设置单据类型标签(设置为销售模块)
          this.billsTypeSign = 0;
          //设置单据分类类型(销售订单)
          this.billsClassify = 1;
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-001';
          //设置默认单据类型(销售订单)
          this.billsTypeDefaultCode = 'DOC-S-006';
          //设置单据操作类型为生成
          this.isGenerateOperateType = 1;
          //显示操作弹框
          this.show_nodeBills = true;
        } else if (command.type == "1") { //生成采购申请单
        if(command.data[0].saleOrderMain.purchaseState==3){
          this.$message({
            type: 'warning',
            message: '该订单为不采购状态，不可生成采购申请单',
            duration: this.elDuration
          })
          return
        }
        //（徐）20220824确认 订单只有未生成的才给予生成
        if(command.data[0].saleOrderMain.purchaseState==4||command.data[0].saleOrderMain.purchaseState==2||command.data[0].saleOrderMain.purchaseState==1){
          this.$message({
            type: 'warning',
            message: '该数据已有生成单据，请不要重复生成',
            duration: this.elDuration
          })
          return
        }
          //判断是否有(采购申请单)新增权限
          if (this.commonJsExtend.isHaveThePower(23, 1, 2, '新增')) {
            return
          }
          //将操作设置为新增
          this.operateType = 0;
          //获取需要回显的单据id
          this.generateBillsId = command.data[0].saleOrderMain.id;
          //获取需要生成其他单据的数据源
          this.generateBillsOriginData = command.data[0];
          //设置源单分类标识
          this.generateBillsSign = 0;
          //设置单据类型标签(设置为采购模块)
          this.billsTypeSign = 3;
          //设置单据分类类型(采购申请单)
          this.billsClassify = 0;
          //设置单据分类code为采购
          this.nodeTypeCode = 'SYS-NODE-TYPE-002';
          //设置默认单据类型(采购申请单)
          this.billsTypeDefaultCode = 'DOC-P-001';
          //设置单据操作类型为生成
          this.isGenerateOperateType = 1;
          //显示操作弹框
          this.show_nodeBills = true;
        } else if (command.type == "2") { //生成生产单
        //（徐）20220824确认 订单只有未生成的才给予生成
        if(command.data[0].saleOrderMain.mesState==2||command.data[0].saleOrderMain.mesState==4||command.data[0].saleOrderMain.mesState==1){
          this.$message({
            type: 'warning',
            message: '该数据已有生成的数据，请不要重复生成',
            duration: this.elDuration
          })
          return
        }
          //设置生成的单据code
          let productData = {
            order_id: this.generateBillsId, //生产生产单的订单id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '生产单生成中,请稍候...');
          //发送请求
          productApi.addMesMainByOrder(productData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取生成详情数量值
              this.generateResData = res.data;
              //显示生成详情弹框
              this.show_generateMes = true;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (command.type == "3") { //生成销售出库单
        //（徐）20220824确认 订单只有未生成的才给予生成
        if(command.data[0].saleOrderMain.depotState==2||command.data[0].saleOrderMain.depotState==3||command.data[0].saleOrderMain.depotState==1){
          this.$message({
            type: 'warning',
            message: '该数据已有生成的数据，请不要重复生成',
            duration: this.elDuration
          })
          return
        }
          //判断是否有(销售出库)新增权限
          if (this.commonJsExtend.isHaveThePower(15, 2, 2, '新增')) {
            return
          }
          //将操作设置为新增
          this.operateType = 0;
          //获取需要回显的单据id
          this.generateBillsId = command.data[0].saleOrderMain.id;
          //获取需要生成其他单据的数据源
          this.generateBillsOriginData = command.data[0];
          //设置源单分类标识
          this.generateBillsSign = 0;
          //设置单据类型标签(设置为库存模块)
          this.billsTypeSign = 2;
          //设置单据分类类型(出库单)
          this.billsClassify = 1;
          //设置单据分类code为库存
          this.nodeTypeCode = 'SYS-NODE-TYPE-004';
          //设置默认单据类型(销售出库单)
          this.billsTypeDefaultCode = 'DOC-S-007';
          //设置单据操作类型为生成
          this.isGenerateOperateType = 1;
          //显示操作弹框
          this.show_nodeBills = true;
        } else if (command.type == "4") { //生成采购订单
        //（徐）20220824确认 订单只有未生成的才给予生成
        if(command.data[0].saleOrderMain.purchaseState==4||command.data[0].saleOrderMain.purchaseState==2||command.data[0].saleOrderMain.purchaseState==1){
          this.$message({
            type: 'warning',
            message: '该数据已有生成的数据，请不要重复生成',
            duration: this.elDuration
          })
          return
        }
          //判断是否有(采购订单)新增权限
          if (this.commonJsExtend.isHaveThePower(23, 2, 2, '新增')) {
            return
          }
          //将操作设置为新增
          this.operateType = 0;
          //获取需要回显的单据id
          this.generateBillsId = command.data[0].saleOrderMain.id;
          //获取需要生成其他单据的数据源
          this.generateBillsOriginData = command.data[0];
          //设置源单分类标识
          this.generateBillsSign = 0;
          //设置单据类型标签(设置为采购模块)
          this.billsTypeSign = 3;
          //设置单据分类类型(采购订单)
          this.billsClassify = 1;
          //设置单据分类code为采购
          this.nodeTypeCode = 'SYS-NODE-TYPE-002';
          //设置默认单据类型(采购订单)
          this.billsTypeDefaultCode = 'DOC-P-002';
          //设置单据操作类型为生成
          this.isGenerateOperateType = 1;
          //显示操作弹框
          this.show_nodeBills = true;
        }
      },


      /* 获取关联的收款单据信息 */
      getRelvanceDetail(data, sign, classify) {
        //判断是否有相应的权限
        if (this.commonJsExtend.judgeBillsSelPowerBySign(sign, classify)) {
          this.$message({
            type: 'warning',
            message: '管理员未给您开通相应查询权限,请确认!',
            duration: this.elDuration
          })
          return;
        }
        //将操作设置为修改或预览
        this.operateType = 2;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据类型标签(设置为库存模块)
        this.billsTypeSign = sign;
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.statusId, //节点id
          processId: data.processId, //流程id
        }
        //设置默认单据类型
        this.billsTypeDefaultCode = data.billingTypeInfo.code;
        //设置单据分类类型
        this.billsClassify = classify;
        //设置单据分类code
        this.nodeTypeCode = this.commonJsExtend.findNodeTypeCodeBySign(sign);
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 跳转到生产单列表 */
      skipMesListPage() {
        this.$router.push({
          path: 'productionplan_view?productType=0&productBillCode=DOC-M-001'
        })
      },

      /* 获取列表已选数据 */
      getSelectedOfferData(selection, row) {
        //获取单选数据
        this.offerSelData = [];
        if (selection.length != 0) {
          selection.forEach(item => {
            this.offerSelData.push(item);
          })
        }
        //设置单选
        // this.$refs.sellTableList.clearSelection();
        // this.$refs.sellTableList.toggleRowSelection(row);
      },

      /* 全选列表数据 */
      getSelectedAllData(selection) {
        //获取数据
        this.offerSelData = [];
        if (selection.length != 0) {
          selection.forEach(item => {
            this.offerSelData.push(item);
          })
        }
        // this.offerSelData = [];
        // //直接清除,不可全选
        // this.$refs.sellTableList.clearSelection();
      },

      /* 分页数据大小改变 */
      changePageSize(val) {
        //获取分页大小
        this.pageData.pageSize = val;
        //请求数据
        this.getListDataByCondition(this.sel_searchBtn)
      },

      /* 当前页改变 */
      changeCurrentPage(val) {
        //获取当前页
        this.pageData.currentPage = val;
        //请求数据
        this.getListDataByCondition(this.sel_searchBtn)
      },

      /* 获取合计数据 */
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          let property = '';
          let sumPropertys = ['totalNumber', 'money', 'discount', 'practicalAmount',
            'printNum'
          ]; //指定需要计算的字段(必须在saleOrderMain里)
          if (column.property) {
            property = column.property.split('.')[1];
          }
          const values = data.map(item => Number(item.saleOrderMain[property]));
          if (sumPropertys.indexOf(property) != -1) {
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = this.commonJsExtend.getDecimalsLen(sums[index], 3);
            } else {
              sums[index] = '';
            }
          }
        });
        return sums;
      },

      /* 初始化状态数据 */
      initStateList() {
        this.offerStateList = [{
            id: 0,
            name: '现货订单',
          },
          {
            id: 1,
            name: '定制订单',
          },
          {
            id: 2,
            name: '未生成订单',
          },
          {
            id: 3,
            name: '已生成订单',
          },
          {
            id: 4,
            name: '未生成采购单',
          },
          {
            id: 5,
            name: '已生成采购单',
          },
          {
            id: 6,
            name: '未生成生产任务',
          },
          {
            id: 7,
            name: '已生成生产任务',
          },
          {
            id: 8,
            name: '未生成委外加工',
          },
          {
            id: 9,
            name: '已生成委外加工',
          }
        ]
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('saleListArr', this.saleListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('saleListArr', this.saleListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('saleListArr', this.showListFiledData);
      },

      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },

      /* 取消工时弹框 */
      closeHourseBox() {
        this.show_hourseBox = false;
      },

      /* 设置查看单据状态为预览(子组件调用) */
      setOperateTypeIsView() {
        this.operateType = 2;
      },

      xhdh() { //现货订单
        this.show_ddh = false;
        this.show_scd = false;
        this.show_wwd = false;
      },
      dzdh() { //定制订单
        this.show_ddh = false;
        this.show_scd = true;
        this.show_wwd = true;
      },
      qt() {
        this.show_ddh = true;
        this.show_scd = true;
        this.show_wwd = true;
      },
    },
    components: {
      nodeBills,
      customerList,
      userPowerSelect,
      billExport,
      relvanceBillsTip,
      relvanceBillTipStrip,
      rejectBillTips
    }
  }
</script>
<style lang="less" scoped>
  .rotateX {
    transform: rotateX(180deg);
  }
</style>
