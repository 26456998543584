<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据关联信息显示tip(框框形式)
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-tooltip placement="right" effect="light">
      <div slot="content">
        <div class="billsList_RelvanceTipChunk searchClassfiy">
          <!-- 单据内容框 -->
          <ul class="billsBox">
            <!-- 无数据时显示 -->
            <li class="billsItem" v-if="relevanceDepotMain.length == 0">
              <ul class="ItemBox">
                <!-- 公共参数值 -->
                <li class="itemLi">
                  <span class="title">单据名:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
                <li class="itemLi">
                  <span class="title">日期:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
                <li class="itemLi">
                  <span class="title">单号:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
                <li class="itemLi">
                  <span class="title">审核状态:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>

                <!-- 销售,采购,库存公用 -->
                <li class="itemLi">
                  <span class="title">数量:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>

                <!--  -->
                <li class="itemLi">
                  <span class="title">已出库:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
                <li class="itemLi">
                  <span class="title">待出库:</span>
                  <span class="valueNo" v-if="isGetEndData">暂无数据</span>
                  <span class="valueNo" v-else>数据加载中 <i class="el-icon-loading"></i></span>
                </li>
              </ul>
            </li>
            <!-- 循环显示多个内容 -->
            <li class="billsItem" v-for="(elem,index) in relevanceDepotMain" v-else>
              <ul class="ItemBox">
                <li class="itemLi">
                  <span class="title">单据名:</span>
                  <span class="value">{{elem.billingTypeInfo.bills_type}}</span>
                </li>
                <li class="itemLi">
                  <span class="title">日期:</span>
                  <span class="value">{{elem.billsDate}}</span>
                </li>
                <li class="itemLi">
                  <span class="title">单号:</span>
                  <span class="value hover_span" title="点击查看详情">{{elem.billsNo}}</span>
                </li>
                <li class="itemLi">
                  <span class="title">审核状态:</span>
                  <span class="value color_info" v-if="elem.isSubmit== 0">未提交</span>
                  <span class="value color_warning" v-if="elem.isSubmit== 1">审核中</span>
                  <span class="value color_danger" v-if="elem.isSubmit== 2">已驳回</span>
                  <span class="value color_primary" v-if="elem.isSubmit== 3">复审中</span>
                  <span class="value color_success" v-if="elem.isSubmit== 4">已审核</span>
                </li>
                <li class="itemLi">
                  <span class="title">数量:</span>
                  <span class="value">100</span>
                </li>
                <li class="itemLi">
                  <span class="title">已出库:</span>
                  <span class="value color_success">60</span>
                </li>
                <li class="itemLi">
                  <span class="title">待出库:</span>
                  <span class="value color_warning">40</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <el-button v-if="originBillsData.saleOrderMain.depotState == 0" type="danger" size="mini"
        @mouseenter.native="startGetBillsRelvance(2,originBillsData.saleOrderMain)"
        @mouseleave.native="endGetBillsRelvance()">未出库</el-button>
      <el-button v-if="originBillsData.saleOrderMain.depotState == 1" type="primary" size="mini"
        @mouseenter.native="startGetBillsRelvance(2,originBillsData.saleOrderMain)"
        @mouseleave.native="endGetBillsRelvance()">出库中</el-button>
      <el-button v-if="originBillsData.saleOrderMain.depotState == 2" type="success" size="mini"
        @mouseenter.native="startGetBillsRelvance(2,originBillsData.saleOrderMain)"
        @mouseleave.native="endGetBillsRelvance()">已出库</el-button>
    </el-tooltip>
  </div>
</template>

<script>
  //导入接口
  import sellApi from '@/network/sell/bills.js'; //销售模块

  export default{
    props:{
      // 源单数据
      originBillsData:{
        type:Object,
        default(){
          return {}
        }
      }
    },
    data(){
      return{
        relevanceDepotMain:[],

        // 控制开关
        isGetEndData:true,//开始查询单据信息(为0时不查询 为1时查询)

        //定时器相关
        timerGetBills:null,//获取单据关联单据定时器
      }
    },
    methods:{
      /* 鼠标移入时延迟获取单据关联信息(sign:获取的关联单据模块) */
      startGetBillsRelvance(sign,data) {
        //清除数据
        this.relevanceDepotMain = [];//出库单数据
        //设置数据请求中
        this.isGetEndData = false;
        //延迟获取数据
        this.timerGetBills = setTimeout(()=>{
          //定义请求数据
          let getData = {
            id:data.id
          }
          //判断需要发送请求的模块
          if(sign == 2){//库存模块
            sellApi.findOrderDepotMainOut(getData).then(res=>{
              if(res.code == 200){
                //获取数据
                this.relevanceDepotMain = res.data;
                //设置数据已获取完成
                this.isGetEndData = true;
              }else{
                this.$message({
                  type:error,
                  message:res.message,
                  duration:this.elDuration
                })
              }
            })
          }
        },1000);
      },

      /* 鼠标移出时清除定时器且清空数据 */
      endGetBillsRelvance() {
        //清除定时器
        clearTimeout(this.timerGetBills);
        //数据本次获取数据结束
        this.isGetEndData = true;
      },
    }
  }
</script>

<style>
</style>
